//export all components from a central file.
export {App} from './App'
export {NavPage} from './NavPage';
export {Intro} from './Home/Intro';
export {default as Home} from './Home/Home'
export {HomeAbout} from './Home/HomeAbout';
export {HomeProjects} from './Home/HomeProjects';
export {HomePhotography} from './Home/HomePhotography';
export {Footer} from './Footer';
export {Routes} from './Routes';
export {default as SideBar} from './SideBar';
export {default as About} from './About';
export {SingleProject} from './Projects/SingleProject';
export {default as Projects} from './Projects/Projects'
export {default as ProjectDetail} from './Projects/ProjectDetail'
export {Arrow} from './Home/Plx/Arrow'
export {default as Photos} from './Photos'
export {default as ScrollToTop} from './ScrollToTop'
